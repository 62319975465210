<template>
  <v-container fluid class="px-4">
    <v-row class="ml-1 mb-3">
      <v-col cols="12">
        <div class="d-flex flex-row align-center">
          <v-btn
            x-small
            class="white--text mt-2"
            color="warning"
            elevation="0"
            @click="
              () =>
                this.$router.push({
                  path: `/abstract-campaign/${this.$route.params.id1}/campaign/${this.$route.params.id2}`,
                })
            "
          >
            <v-icon small> mdi-arrow-left </v-icon>
          </v-btn>
          <h1 class="ml-5 mt-2" style="font-weight: 200">
            Simulador {{ processedSingleCampaign.processedName }}
          </h1>
          <v-tooltip v-if="!hasActiveCampaignModifiers" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-5 mt-4 grey--text"
                elevation="0"
                v-bind="attrs"
                v-on="on"
                small
                :disabled="isUserManager || !userHasLock"
                >Reestablecer Todos</v-btn
              >
            </template>
            <span>No hay modificadores Activos</span>
          </v-tooltip>
          <v-tooltip v-else top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-5 mt-4"
                elevation="0"
                v-bind="attrs"
                v-on="on"
                small
                :disabled="isUserManager || !userHasLock"
                color="red red--text lighten-5"
                @click="resetAllModifiers"
                >Reestablecer Todo</v-btn
              >
            </template>
            <span>Esta acción eliminara TODOS los modificadores</span>
          </v-tooltip>
        </div>
        <v-expansion-panels flat multiple v-model="panel" class="mb-2">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <b :class="'ml-2 grey--text h2'"> KPIs de Campaña </b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <InfoGrid class="" :infos="campaignKpi" valueClass="display-2" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="my-1">
            <v-divider></v-divider>
          </v-expansion-panel>
          <v-expansion-panel class="mb-5">
            <v-expansion-panel-header>
              <b :class="'ml-2 grey--text h2'"> KPIs de Simulación </b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <InfoGrid
                class=""
                :infos="campaignSimulationKpi"
                valueClass="display-2"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="d-flex flex-row align-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mb-2"
                v-if="!isLock"
                color=""
                v-bind="attrs"
                v-on="on"
                >mdi-lock-open-outline</v-icon
              >
              <v-icon v-else class="mb-2" color="red" v-bind="attrs" v-on="on"
                >mdi-lock-outline</v-icon
              >
            </template>
            <span v-if="!isLock">Desbloqueado</span>
            <span v-else>Bloqueado</span>
          </v-tooltip>

          <v-tabs v-model="tab" class="mb-5">
            <v-tab
              @click="
                resetFilters();
                exitEdit();
              "
              class="ms-4"
              >Rinde</v-tab
            >
            <v-tab
              @click="
                resetFilters();
                exitEdit();
              "
              >Precio</v-tab
            >
            <v-tab
              @click="
                resetFilters();
                exitEdit();
              "
              >Costos de Familia</v-tab
            >
            <v-tab
              @click="
                resetFilters();
                exitEdit();
              "
              >Flete</v-tab
            >
            <v-tab
              @click="
                resetFilters();
                exitEdit();
              "
              >Alquiler Quintales Fijos</v-tab
            >
            <v-tab
              @click="
                resetFilters();
                exitEdit();
              "
              >Cashflow de Simulación</v-tab
            >
          </v-tabs>
          <v-tooltip v-if="userHasLock" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mb-5 me-10"
                v-on="on"
                v-bind="attrs"
                color="primary"
                @click="releaseUserLock"
                small
              >
                <v-icon color=""> mdi-lock-open-check-outline </v-icon>
              </v-btn>
            </template>
            <span>Desbloquear para otros usuarios</span>
          </v-tooltip>
          <v-tooltip v-if="!isLock" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mb-5 me-10"
                v-on="on"
                v-bind="attrs"
                color="warning"
                @click="setUserLockIfNotSet"
                small
              >
                <v-icon color=""> mdi-pen-lock </v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </div>
        <v-tabs-items class="" v-model="tab">
          <div
            v-if="isLock && !userHasLock"
            class="
              d-flex
              flex-row
              text-start
              w-100
              ms-2
              mt-1
              mb-2
              justify-space-between
            "
            style="z-index: 100000"
          >
            <div class="me-2 d-flex">
              <div>
                <v-icon color="primary" class="me-1 blink"
                  >mdi-account-badge-outline</v-icon
                >
                <span class="caption font-weight-medium mt-1 me-4"
                  >{{ userWithLockUsername }}
                </span>
                <v-icon color="warning" class="me-1"
                  >mdi-clock-edit-outline</v-icon
                >
                <span class="caption font-weight-medium mt-1">{{
                  this.simulationLockDate
                }}</span>
              </div>
            </div>
          </div>
          <v-overlay
            v-if="showLockOverlay"
            :opacity="0.08"
            class="pa-5 mb-2"
            absolute
          >
            <v-tooltip v-if="isLock" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                  :disabled="isUserManager"
                  @click="setUserIdLock"
                  @mouseover="showOpenLock = true"
                  @mouseleave="showOpenLock = false"
                >
                  <v-icon v-if="showOpenLock" color="" class="">
                    mdi-lock-open-variant-outline
                  </v-icon>
                  <v-icon v-else color=""> mdi-lock-outline </v-icon>
                </v-btn>
              </template>
              <span>Desbloquear</span>
            </v-tooltip>
          </v-overlay>
          <v-tab-item>
            <v-row class="">
              <v-col class="ma-2">
                <div class="d-flex flex-column">
                  <div class="d-flex flex-row">
                    <v-select
                      label="Mostrar"
                      :items="[
                        { name: 'Todos', value: 'all' },
                        { name: 'Activos', value: 'activeOnly' },
                        { name: 'Inactivos', value: 'inActiveOnly' },
                      ]"
                      item-text="name"
                      item-value="value"
                      v-model="modifiersTypeFilter"
                      @change="exitEdit"
                      dense
                      type="text"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-select
                      label="Region"
                      :items="regions"
                      item-text="abbreviation"
                      item-value="id"
                      v-model="regionIdFilter"
                      @change="exitEdit"
                      dense
                      clearable
                      type="number"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-select
                      label="Especie"
                      :items="species"
                      item-text="name"
                      item-value="id"
                      v-model="speciesIdFilter"
                      @change="exitEdit"
                      clearable
                      dense
                      type="number"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-btn
                      class="ma-2 mt-2"
                      elevation="0"
                      small
                      color="info info--text lighten-5"
                      :disabled="
                        !regionIdFilter &&
                        !speciesIdFilter &&
                        modifiersTypeFilter === 'all'
                      "
                      @click="
                        speciesIdFilter = null;
                        regionIdFilter = null;
                        modifiersTypeFilter = 'all';
                        exitEdit();
                      "
                      ><v-icon>mdi-restart</v-icon></v-btn
                    >
                  </div>

                  <div
                    class="
                      d-flex
                      flex-column
                      mt-3
                      mb-4
                      background-grey
                      pa-2
                      px-4
                    "
                  >
                    <div class="d-flex flex-row">
                      <v-slider
                        class="mt-4"
                        thumb-label
                        min="20"
                        max="200"
                        ticks="always"
                        step="5"
                        tick-size="1"
                        v-model="modsNewValue"
                        :disabled="!allowEdit"
                      ></v-slider>
                      <div class="d-flex flex-row align-top">
                        <v-text-field
                          v-model="modsNewValue"
                          dense
                          ref="inputTest"
                          type="number"
                          outlined
                          class="ms-2 me-2 mt-3"
                          suffix="%"
                          :readonly="!allowEdit"
                          :rules="modRules"
                        >
                        </v-text-field>
                      </div>
                      <v-btn
                        v-if="allowEdit"
                        class="mt-4 ms-3"
                        elevation="0"
                        color="primary"
                        small
                        @click="saveEdits()"
                      >
                        Guardar
                      </v-btn>
                      <v-tooltip v-if="filteredModTableData.length == 0" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mt-4 ms-3 grey--text"
                            elevation="0"
                            v-bind="attrs"
                            v-on="on"
                            small
                            >{{ editText }}</v-btn
                          >
                        </template>
                        <span>Ningun filtro seleccionado</span>
                      </v-tooltip>
                      <v-btn
                        v-else
                        class="mt-4 ms-3"
                        elevation="0"
                        :color="editButtonColor"
                        small
                        :disabled="isUserManager"
                        @click="!allowEdit ? enterEdit() : exitEdit()"
                        >{{ editText }}</v-btn
                      >
                    </div>
                    <div class="d-flex flex-row mb-0 pb-0">
                      <span class="text-caption warning--text">
                        {{ alertUserModifiersFiltersApplied }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row class="">
              <v-col class="ma-2">
                <div class="d-flex flex-column">
                  <div class="d-flex flex-row">
                    <v-select
                      label="Mostrar"
                      :items="[
                        { name: 'Todos', value: 'all' },
                        { name: 'Activos', value: 'activeOnly' },
                        { name: 'Inactivos', value: 'inActiveOnly' },
                      ]"
                      item-text="name"
                      item-value="value"
                      v-model="modifiersTypeFilter"
                      @change="exitEdit"
                      dense
                      type="text"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-select
                      label="Especie"
                      :items="species"
                      item-text="name"
                      item-value="id"
                      v-model="speciesIdFilter"
                      @change="exitEdit"
                      clearable
                      dense
                      type="number"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-btn
                      class="ma-2 mt-2"
                      elevation="0"
                      small
                      color="info info--text lighten-5"
                      :disabled="
                        !speciesIdFilter && modifiersTypeFilter === 'all'
                      "
                      @click="
                        speciesIdFilter = null;
                        modifiersTypeFilter = 'all';
                        exitEdit();
                      "
                      ><v-icon>mdi-restart</v-icon></v-btn
                    >
                  </div>

                  <div
                    class="
                      d-flex
                      flex-column
                      mt-3
                      mb-4
                      background-grey
                      pa-2
                      px-4
                    "
                  >
                    <div class="d-flex flex-row">
                      <v-slider
                        class="mt-4"
                        thumb-label
                        min="20"
                        max="200"
                        ticks="always"
                        step="5"
                        tick-size="1"
                        v-model="modsNewValue"
                        :disabled="!allowEdit"
                      ></v-slider>
                      <div class="d-flex flex-row align-top">
                        <v-text-field
                          v-model="modsNewValue"
                          dense
                          ref="inputTest"
                          type="number"
                          outlined
                          class="ms-2 me-2 mt-3"
                          suffix="%"
                          :readonly="!allowEdit"
                          :rules="modRules"
                        >
                        </v-text-field>
                      </div>
                      <v-btn
                        v-if="allowEdit"
                        class="mt-4 ms-3"
                        elevation="0"
                        color="primary"
                        small
                        @click="saveEdits()"
                      >
                        Guardar
                      </v-btn>
                      <v-tooltip v-if="filteredModTableData.length == 0" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mt-4 ms-3 grey--text"
                            elevation="0"
                            v-bind="attrs"
                            v-on="on"
                            small
                            >{{ editText }}</v-btn
                          >
                        </template>
                        <span>Ningun filtro seleccionado</span>
                      </v-tooltip>
                      <v-btn
                        v-else
                        class="mt-4 ms-3"
                        :color="editButtonColor"
                        elevation="0"
                        small
                        :disabled="isUserManager"
                        @click="!allowEdit ? enterEdit() : exitEdit()"
                        >{{ editText }}</v-btn
                      >
                    </div>
                    <div class="d-flex flex-row">
                      <span class="text-caption warning--text">
                        {{ alertUserModifiersFiltersApplied }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row class="">
              <v-col class="ma-2">
                <div class="d-flex flex-column">
                  <div class="d-flex flex-row">
                    <v-select
                      label="Mostrar"
                      :items="[
                        { name: 'Todos', value: 'all' },
                        { name: 'Activos', value: 'activeOnly' },
                        { name: 'Inactivos', value: 'inActiveOnly' },
                      ]"
                      item-text="name"
                      item-value="value"
                      v-model="modifiersTypeFilter"
                      @change="exitEdit"
                      dense
                      type="text"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-select
                      label="Region"
                      :items="regions"
                      item-text="abbreviation"
                      item-value="id"
                      v-model="regionIdFilter"
                      @change="exitEdit"
                      dense
                      clearable
                      type="number"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-select
                      label="Especie"
                      :items="species"
                      item-text="name"
                      item-value="id"
                      v-model="speciesIdFilter"
                      @change="exitEdit"
                      clearable
                      dense
                      type="number"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-select
                      label="Familia"
                      :items="costItemFamiliesFiltered"
                      v-model="costItemFamilyIdFilter"
                      @change="exitEdit"
                      item-text="name"
                      item-value="id"
                      clearable
                      dense
                      type="number"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-btn
                      class="ma-2 mt-2"
                      elevation="0"
                      small
                      color="info info--text lighten-5"
                      :disabled="
                        !regionIdFilter &&
                        !speciesIdFilter &&
                        !costItemFamilyIdFilter &&
                        modifiersTypeFilter === 'all'
                      "
                      @click="
                        regionIdFilter = null;
                        speciesIdFilter = null;
                        costItemFamilyIdFilter = null;
                        modifiersTypeFilter = 'all';
                        exitEdit();
                      "
                      ><v-icon>mdi-restart</v-icon></v-btn
                    >
                  </div>

                  <div
                    class="
                      d-flex
                      flex-column
                      mt-3
                      mb-4
                      background-grey
                      pa-2
                      px-4
                    "
                  >
                    <div class="d-flex flex-row">
                      <v-slider
                        class="mt-4"
                        thumb-label
                        min="20"
                        max="200"
                        ticks="always"
                        step="5"
                        tick-size="1"
                        v-model="modsNewValue"
                        :disabled="!allowEdit"
                      ></v-slider>
                      <div class="d-flex flex-row align-top">
                        <v-text-field
                          v-model="modsNewValue"
                          dense
                          ref="inputTest"
                          type="number"
                          outlined
                          class="ms-2 me-2 mt-3"
                          suffix="%"
                          :readonly="!allowEdit"
                          :rules="modRules"
                        >
                        </v-text-field>
                      </div>
                      <v-btn
                        v-if="allowEdit"
                        class="mt-4 ms-3"
                        elevation="0"
                        color="primary"
                        small
                        :disabled="isUserManager"
                        @click="saveEdits()"
                      >
                        Guardar
                      </v-btn>
                      <v-tooltip v-if="filteredModTableData.length == 0" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mt-4 ms-3 grey--text"
                            elevation="0"
                            v-bind="attrs"
                            v-on="on"
                            small
                            >{{ editText }}</v-btn
                          >
                        </template>
                        <span>Ningun filtro seleccionado</span>
                      </v-tooltip>
                      <v-btn
                        v-else
                        class="mt-4 ms-3"
                        elevation="0"
                        :color="editButtonColor"
                        small
                        :disabled="isUserManager"
                        @click="!allowEdit ? enterEdit() : exitEdit()"
                        >{{ editText }}</v-btn
                      >
                    </div>
                    <div class="d-flex flex-row">
                      <span class="text-caption warning--text">
                        {{ alertUserModifiersFiltersApplied }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row class="">
              <v-col class="ma-2">
                <div class="d-flex flex-column">
                  <div class="d-flex flex-row">
                    <v-select
                      label="Mostrar"
                      :items="[
                        { name: 'Todos', value: 'all' },
                        { name: 'Activos', value: 'activeOnly' },
                        { name: 'Inactivos', value: 'inActiveOnly' },
                      ]"
                      item-text="name"
                      item-value="value"
                      v-model="modifiersTypeFilter"
                      @change="exitEdit"
                      dense
                      type="text"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-select
                      label="Region"
                      :items="regions"
                      item-text="abbreviation"
                      item-value="id"
                      v-model="regionIdFilter"
                      @change="exitEdit"
                      dense
                      clearable
                      type="number"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-select
                      label="Especie"
                      :items="species"
                      item-text="name"
                      item-value="id"
                      v-model="speciesIdFilter"
                      @change="exitEdit"
                      clearable
                      dense
                      type="number"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-btn
                      class="ma-2 mt-2"
                      elevation="0"
                      small
                      color="info info--text lighten-5"
                      :disabled="
                        !regionIdFilter &&
                        !speciesIdFilter &&
                        modifiersTypeFilter === 'all'
                      "
                      @click="
                        speciesIdFilter = null;
                        regionIdFilter = null;
                        modifiersTypeFilter = 'all';
                        exitEdit();
                      "
                      ><v-icon>mdi-restart</v-icon></v-btn
                    >
                  </div>

                  <div
                    class="
                      d-flex
                      flex-column
                      mt-3
                      mb-4
                      background-grey
                      pa-2
                      px-4
                    "
                  >
                    <div class="d-flex flex-row">
                      <v-slider
                        class="mt-4"
                        thumb-label
                        min="20"
                        max="200"
                        ticks="always"
                        step="5"
                        tick-size="1"
                        v-model="modsNewValue"
                        :disabled="!allowEdit"
                      ></v-slider>
                      <div class="d-flex flex-row align-top">
                        <v-text-field
                          v-model="modsNewValue"
                          dense
                          ref="inputTest"
                          type="number"
                          outlined
                          class="ms-2 me-2 mt-3"
                          suffix="%"
                          :readonly="!allowEdit"
                          :rules="modRules"
                        >
                        </v-text-field>
                      </div>
                      <v-btn
                        v-if="allowEdit"
                        class="mt-4 ms-3"
                        elevation="0"
                        color="primary"
                        small
                        @click="saveEdits()"
                      >
                        Guardar
                      </v-btn>
                      <v-tooltip v-if="filteredModTableData.length == 0" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mt-4 ms-3 grey--text"
                            elevation="0"
                            v-bind="attrs"
                            v-on="on"
                            small
                            >{{ editText }}</v-btn
                          >
                        </template>
                        <span>Ningun filtro seleccionado</span>
                      </v-tooltip>
                      <v-btn
                        v-else
                        class="mt-4 ms-3"
                        elevation="0"
                        :color="editButtonColor"
                        small
                        :disabled="isUserManager"
                        @click="!allowEdit ? enterEdit() : exitEdit()"
                        >{{ editText }}</v-btn
                      >
                    </div>
                    <div class="d-flex flex-row mb-0 pb-0">
                      <span class="text-caption warning--text">
                        {{ alertUserModifiersFiltersApplied }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row class="">
              <v-col class="ma-2">
                <div class="d-flex flex-column">
                  <div class="d-flex flex-row">
                    <v-select
                      label="Mostrar"
                      :items="[
                        { name: 'Todos', value: 'all' },
                        { name: 'Activos', value: 'activeOnly' },
                        { name: 'Inactivos', value: 'inActiveOnly' },
                      ]"
                      item-text="name"
                      item-value="value"
                      v-model="modifiersTypeFilter"
                      @change="exitEdit"
                      dense
                      type="text"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>
                    <v-select
                      label="Region"
                      :items="regions"
                      item-text="abbreviation"
                      item-value="id"
                      v-model="regionIdFilter"
                      @change="exitEdit"
                      dense
                      clearable
                      type="number"
                      class="managrx-input ma-2 pb-2"
                    ></v-select>

                    <v-btn
                      class="ma-2 mt-2"
                      elevation="0"
                      small
                      color="info info--text lighten-5"
                      :disabled="
                        !regionIdFilter && modifiersTypeFilter === 'all'
                      "
                      @click="
                        regionIdFilter = null;
                        modifiersTypeFilter = 'all';
                        exitEdit();
                      "
                      ><v-icon>mdi-restart</v-icon></v-btn
                    >
                  </div>

                  <div
                    class="
                      d-flex
                      flex-column
                      mt-3
                      mb-4
                      background-grey
                      pa-2
                      px-4
                    "
                  >
                    <div class="d-flex flex-row">
                      <v-slider
                        class="mt-4"
                        thumb-label
                        min="-20"
                        max="20"
                        ticks="always"
                        step="1"
                        tick-size="0"
                        v-model="rentModsNewValue"
                        :disabled="!allowEdit"
                      ></v-slider>
                      <div class="d-flex flex-row align-top">
                        <v-text-field
                          v-model="rentModsNewValue"
                          dense
                          ref="inputTest"
                          type="number"
                          outlined
                          class="ms-2 me-2 mt-3"
                          suffix="qq"
                          :readonly="!allowEdit"
                          :rules="rentModRules"
                        >
                        </v-text-field>
                      </div>
                      <v-btn
                        v-if="allowEdit"
                        class="mt-4 ms-3"
                        elevation="0"
                        color="primary"
                        small
                        @click="saveEdits()"
                      >
                        Guardar
                      </v-btn>
                      <v-tooltip v-if="filteredModTableData.length == 0" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mt-4 ms-3 grey--text"
                            elevation="0"
                            v-bind="attrs"
                            v-on="on"
                            small
                            >{{ editText }}</v-btn
                          >
                        </template>
                        <span>Ningun filtro seleccionado</span>
                      </v-tooltip>
                      <v-btn
                        v-else
                        class="mt-4 ms-3"
                        :color="editButtonColor"
                        elevation="0"
                        small
                        :disabled="isUserManager"
                        @click="!allowEdit ? enterEdit() : exitEdit()"
                        >{{ editText }}</v-btn
                      >
                    </div>
                    <div class="d-flex flex-row mb-0 pb-0">
                      <span class="text-caption warning--text">
                        {{ alertUserModifiersFiltersApplied }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <DenseCashflowTable
              :rows="engagementCashflowRows"
              :totalHaToolTip="campaignTotalHaToolTip"
              :columns="engagementCashflowColumns"
              :isResourceUtilization="false"
              :startDate="campaignStartDate"
              :endDate="campaignEndDate"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>

      <v-col cols="12" v-if="tab != cashflowTab">
        <div class="d-flex flex-row justify-space-between mt-0 pt-0 mb-2">
          <v-tooltip v-if="activeSelectedModifiers.length === 0" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="grey--text mr-2 mt-2"
                elevation="0"
                v-bind="attrs"
                v-on="on"
                small
                :disabled="isUserManager || !userHasLock"
                >Reestablecer Seleccionados</v-btn
              >
            </template>
            <span>Ningun modificador activo seleccionado</span>
          </v-tooltip>
          <v-btn
            v-else
            class="mr-2 mt-2"
            elevation="0"
            small
            :disabled="isUserManager || !userHasLock"
            color="red red--text lighten-5"
            @click="resetSelectedModifiers"
            >Reestablecer Seleccionados</v-btn
          >
          <span
            class="text-overline grey--text text-subtitle-2 mt-1"
            style="font-size: 15px !important"
          >
            Resultados: {{ this.filteredModTableData.length }}
          </span>
        </div>
        <AbstractDraftMetricDataTable
          :title="'Modificadores por ' + modTableTypeName"
          :hideFooter="false"
          orderBy="parsedModifier"
          :desc="true"
          :items="filteredModTableData"
          :headers="modTableHeaders"
          :headersWidth="100"
          :hasSearch="false"
          :grupByStatus="false"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AbstractDraftMetricDataTable from "../components/Abstract/AbstractDraftMetricDataTable.vue";
import { unPackAndProcessCashflowKeys } from "../utils";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../managrx/serverCall";
import { campaignLevelTotalHaToolTip } from "../managrx/constants.js";
import { makeCampaignSimulationInfoGrid } from "../managrx/services";
import InfoGrid from "../components/Project/InfoGrid.vue";
import DenseCashflowTable from "../components/WIP/DenseCashflowTable.vue";
import { parseToLocaleString } from "../managrx/utils";

export default {
  name: "SingleCampaignSimulation",
  data() {
    return {
      userWithLock: null,
      showOpenLock: false,
      panel: [0],
      cashflowTab: 5,
      rentTab: 4,
      freightTab: 3,
      costItemFamilyTab: 2,
      priceTab: 1,
      yieldTab: 0,
      isFullLoad: this.$route.meta.isFullLoad,
      tab: "",
      parentTab: "",
      modifiersTypeFilter: "all",
      regionIdFilter: null,
      speciesIdFilter: null,
      costItemFamilyIdFilter: null,
      modifierFilter: null,
      makeActivityGroup: false,
      search: null,
      changeUnitsButtonTagT1: "USD",
      isUsdTotalMetricT1: false,
      metricUnitsT1: "USD/ha",
      changeUnitsButtonTagT3: "USD",
      isUsdTotalMetricT3: false,
      metricUnitsT3: "USD/ha",
      showCampaignDetail: false,
      showMetrics: false,
      showPrices: false,
      showCashflows: false,
      showEngagements: true,
      showActions: false,
      showNewEngagement: false,
      allowEdit: false,
      editText: "Editar",
      editButtonColor: "warning",
      modsNewValue: 100,
      rentModsNewValue: 1,
      // Simulations
      rentModRules: [
        (v) => (!!v && v !== 0) || "El valor no puede ser nulo",
        (v) => (v && v >= -20) || "No puede ser menor a -20qq",
        (v) => (v && v <= 20) || "No puede ser mayor a 20qq",
      ],
      modRules: [
        (v) => (!!v && v !== 0) || "El valor no puede ser nulo",
        (v) => (v && v >= 20) || "No puede ser menor a 20%",
        (v) => (v && v <= 200) || "No puede ser mayor a 200%",
      ],

      yieldModifiersDataTableHeaders: [
        {
          text: "Region",
          value: "regionName",
        },
        {
          text: "Especie",
          value: "speciesName",
        },
        {
          text: "Modificador de Rinde",
          value: "parsedModifier",
        },
      ],
      freightModifiersDataTableHeaders: [
        {
          text: "Region",
          value: "regionName",
        },
        {
          text: "Especie",
          value: "speciesName",
        },
        {
          text: "Modificador de Flete",
          value: "parsedModifier",
        },
      ],
      rentModifiersDataTableHeaders: [
        {
          text: "Region",
          value: "regionName",
        },
        {
          text: "Modificador de Alquiler",
          value: "parsedModifier",
        },
      ],
      priceModifiersDataTableHeaders: [
        {
          text: "Especie",
          value: "speciesName",
        },
        {
          text: "Modificador de Precio",
          value: "parsedModifier",
        },
      ],
      costItemFamilyModifiersDataTableHeaders: [
        {
          text: "Region",
          value: "regionName",
        },
        {
          text: "Especie",
          value: "speciesName",
        },
        {
          text: "Familia",
          value: "costItemFamilyName",
        },
        {
          text: "Modificador de Familia",
          value: "parsedModifier",
        },
      ],
      costItemModifiers: [],
    };
  },
  methods: {
    async releaseUserLock() {
      await this.$store.dispatch("getSingleCampaign", this.$route.params.id2);
      if (this.userId == this.singleCampaign?.userIdSimulationLock) {
        let response = await putRequest(`/campaign/${this.singleCampaign.id}`, {
          userIdSimulationLock: null,
          userLockDatetime: null,
        });
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        }
      }
      this.refreshRoute();
      this.exitEdit();
    },
    async getUserLockName() {
      if (this.singleCampaign?.userIdSimulationLock && !this.userHasLock) {
        let response = await getRequest(
          `/user/all/${this.singleCampaign.userIdSimulationLock}`
        );
        if (response.success) {
          this.userWithLock = response.data;
        } else {
          this.$store.dispatch("setNewNotification", response);
        }
      }
    },
    async setUserIdLock() {
      this.$store.dispatch("setPageLoadingStatus", true);
      let response = await putRequest(`/campaign/${this.singleCampaign.id}`, {
        userIdSimulationLock: this.userId,
        userLockDatetime: new Date().toISOString(),
      });
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      }
      this.refreshRoute();
      this.exitEdit();

      this.$store.dispatch("setPageLoadingStatus", false);
    },
    getAllActiveModifiers() {
      this.$store.dispatch(
        "getYieldModifiersByCampaignId",
        this.$route.params.id2
      );
      this.$store.dispatch(
        "getPriceModifiersByCampaignId",
        this.$route.params.id2
      );
      this.$store.dispatch(
        "getCostItemFamilyModifiersByCampaignId",
        this.$route.params.id2
      );
      this.$store.dispatch(
        "getFreightModifiersByCampaignId",
        this.$route.params.id2
      );
      this.$store.dispatch(
        "getRentModifiersByCampaignId",
        this.$route.params.id2
      );
    },
    checkForKeyShortCut(event) {
      if (
        (this.isLock && !this.userHasLock) ||
        !this.isLock ||
        this.isUserManager
      ) {
        return;
      } else {
        // Press `ctrl+e` to enter in edit mode
        if (event.ctrlKey && event.key === "e") {
          event.preventDefault();
          if (this.filteredModTableData?.length > 0) {
            this.enterEdit();
          }
        }
        // Press `escape` to exit without saving
        if (event.key === "Escape" && this.allowEdit) {
          event.preventDefault();
          this.resetValues();
          this.exitEdit();
        }
        // Press `enter` to save
        if (event.keyCode === 13 && this.allowEdit) {
          event.preventDefault();
          this.saveEdits();
          this.exitEdit();
        }
      }
    },
    resetValues() {
      this.rentModsNewValue = 0;
      this.modsNewValue = 100;
    },
    saveEdits() {
      this.updateActiveModifiers();
      this.addNewModifiers();
    },
    refreshRoute() {
      this.$store.dispatch("setPageLoadingStatus", true);
      this.$store.dispatch("getSingleCampaign", this.$route.params.id2);
      this.getAllActiveModifiers();
      if (!this.isCampaignActivitiesEmpty) {
        this.getCampaignSimulationCashflow();
        this.getCampaignSimulationMetrics();
        this.getCampaignMetrics();
      }
      this.getUserLockName();
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    async setUserLockIfNotSet() {
      if (this.isLock && this.userHasLock) {
        // User already has the lock
        return;
      } else if (this.isLock && !this.userHasLock) {
        // User needs to unlock first
        return;
      } else {
        // Refresh Lock information
        await this.$store.dispatch("getSingleCampaign", this.campaignId);

        if (this.singleCampaign?.userIdSimulationLock != null) {
          // Lock is already set, refrsh to show to the user
          this.refreshRoute();
        } else {
          // Lock is not set, user gets the lock by editing
          this.setUserIdLock();
        }
      }
    },
    async enterEdit() {
      if (!this.isUserManager) {
        this.allowEdit = true;
        this.editText = "Cancelar";
        this.editButtonColor = "info";
        this.$refs.inputTest?.focus();
        this.$refs.inputTest?.focus();
      }
    },
    exitEdit() {
      this.allowEdit = false;
      this.editButtonColor = "warning";
      this.editText = "Editar";
      this.$refs.inputTest?.blur();
      this.$refs.inputTest?.resetValidation();
    },
    async getCampaignSimulationCashflow() {
      this.$store.dispatch("setPageLoadingStatus", true);

      await this.$store.dispatch("getCampaignSimulationCashflow", {
        startYear: this.cashflowStartYear,
        startMonth: this.cashflowStartMonth,
        endYear: this.cashflowEndYear,
        endMonth: this.cashflowEndMonth,
        campaignId: this.$route.params.id2,
      });
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    async getCampaignSimulationMetrics() {
      if (!this.isCampaignActivitiesEmpty) {
        this.$store.dispatch("getCampaignSimulationMetrics", {
          startYear: this.cashflowStartYear,
          startMonth: this.cashflowStartMonth,
          endYear: this.cashflowEndYear,
          endMonth: this.cashflowEndMonth,
          campaignId: this.$route.params.id2,
        });
      }
    },
    async getCampaignMetrics() {
      if (!this.isCampaignActivitiesEmpty) {
        this.$store.dispatch("getCampaignMetrics", {
          startYear: this.cashflowStartYear,
          startMonth: this.cashflowStartMonth,
          endYear: this.cashflowEndYear,
          endMonth: this.cashflowEndMonth,
          campaignId: this.$route.params.id2,
        });
      }
    },
    async updateActiveModifiers() {
      this.$store.dispatch("setPageLoadingStatus", true);
      if (this.activeSelectedModifiers?.length > 0) {
        let endpoint =
          "/module/put/simulation" +
          this.modTableEndpoint +
          `/${this.campaignId}`;
        let payload;
        if (this.tab == this.rentTab) {
          // For RENT TAB, the reset value is 0qq
          if (this.rentModsNewValue === 0) {
            this.resetSelectedModifiers();
          } else {
            payload = {
              bulkUpdate: this.activeSelectedModifiers.map((el) => {
                return { id: el?.id, modifier: this.rentModsNewValue };
              }),
            };
          }
        } else {
          // For OTHERS TABS the reset value is 100%
          if (this.modsNewValue === 100) {
            this.resetSelectedModifiers();
          } else {
            payload = {
              bulkUpdate: this.activeSelectedModifiers.map((el) => {
                return { id: el?.id, modifier: this.modsNewValue / 100 };
              }),
            };
          }
        }
        if (payload) {
          let result = await putRequest(endpoint, payload);
          if (!result?.success) {
            this.$store.dispatch("setNewNotification", result);
          }
        }
      }
      this.refreshRoute();
      this.exitEdit();
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    async addNewModifiers() {
      this.$store.dispatch("setPageLoadingStatus", true);
      if (this.inActiveSelectedModifiers?.length > 0) {
        let endpoint =
          "/module/post/simulation" +
          this.modTableEndpoint +
          `/${this.campaignId}`;
        let payload;
        // Only add new if the selected value is not a reset
        // For RENT TAB, the reset value is 0qq
        // For OTHERS TABS the reset value is 100%
        if (
          (this.tab === this.rentTab && this.rentModsNewValue !== 0) ||
          (this.tab !== this.rentTab && this.modsNewValue !== 100)
        ) {
          if (this.tab == this.yieldTab || this.tab == this.freightTab) {
            // Yield and Freight Modifiers
            payload = {
              bulkNew: this.inActiveSelectedModifiers.map((el) => {
                return {
                  campaignId: this.campaignId,
                  speciesId: el?.speciesId,
                  regionId: el?.regionId,
                  modifier: this.modsNewValue / 100,
                };
              }),
            };
          } else if (this.tab == this.priceTab) {
            // Price modifiers
            payload = {
              bulkNew: this.inActiveSelectedModifiers.map((el) => {
                return {
                  campaignId: this.campaignId,
                  speciesId: el?.speciesId,
                  modifier: this.modsNewValue / 100,
                };
              }),
            };
          } else if (this.tab == this.costItemFamilyTab) {
            // Cost item family Modifiers
            payload = {
              bulkNew: this.inActiveSelectedModifiers.map((el) => {
                return {
                  campaignId: this.campaignId,
                  speciesId: el?.speciesId,
                  regionId: el?.regionId,
                  costItemFamilyId: el?.costItemFamilyId,
                  modifier: this.modsNewValue / 100,
                };
              }),
            };
          } else if (this.tab == this.rentTab) {
            // Cost item family Modifiers
            payload = {
              bulkNew: this.inActiveSelectedModifiers.map((el) => {
                return {
                  campaignId: this.campaignId,
                  regionId: el?.regionId,
                  modifier: this.rentModsNewValue,
                };
              }),
            };
          }
          if (payload) {
            let result = await postRequest(endpoint, payload);
            if (!result?.success) {
              this.$store.dispatch("setNewNotification", result);
            }
          }
        } else {
          this.exitEdit();
        }
      }
      this.refreshRoute();
      this.exitEdit();
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    async resetAllModifiers() {
      this.$store.dispatch("setPageLoadingStatus", true);
      if (this.hasActiveCampaignModifiers > 0) {
        let requestArray = [];
        requestArray.push(
          deleteRequest(`/module/delete/simulation/yield/${this.campaignId}`)
        );

        requestArray.push(
          deleteRequest(`/module/delete/simulation/price/${this.campaignId}`)
        );

        requestArray.push(
          deleteRequest(
            `/module/delete/simulation/cost_item_family/${this.campaignId}`
          )
        );

        requestArray.push(
          deleteRequest(`/module/delete/simulation/freight/${this.campaignId}`)
        );

        requestArray.push(
          deleteRequest(`/module/delete/simulation/rent/${this.campaignId}`)
        );

        let responseArray = await Promise.all(requestArray);
        for (const response of responseArray) {
          if (!response?.success) {
            this.$store.dispatch("setNewNotification", response);
          }
        }
        this.refreshRoute();
        this.exitEdit();
        this.$store.dispatch("setPageLoadingStatus", false);
      }
    },
    async resetSelectedModifiers() {
      this.$store.dispatch("setPageLoadingStatus", true);
      if (this.activeSelectedModifiers?.length > 0) {
        let endpoint =
          "/module/delete/simulation" +
          this.modTableEndpoint +
          `/${this.campaignId}`;

        if (this.deleteQueryParams) {
          endpoint = endpoint + `?q=${this.deleteQueryParams}`;
        }
        let response = await deleteRequest(endpoint);
        if (!response?.success) {
          this.$store.dispatch("setNewNotification", response);
        }
      }
      this.refreshRoute();
      this.exitEdit();
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    resetFilters() {
      this.speciesIdFilter = null;
      this.regionIdFilter = null;
      this.costItemFamilyIdFilter = null;
      this.modifiersTypeFilter = "all";
    },
  },
  computed: {
    ...mapGetters([
      "userTokenInfo",
      "campaignSimulationMetrics",
      "singleCampaign",
      "singleAbstractCampaign",
      "campaignModifiers",
      "campaignMetrics",
      "costItemFamilies",
      "regions",
      "species",
      "yieldModifiers",
      "freightModifiers",
      "rentModifiers",
      "priceModifiers",
      "costItemFamilyModifiers",
      "campaignActivities",
      "isPageLoading",
      "campaignCashflow",
    ]),
    simulationLockDate() {
      return this.processedSingleCampaign?.simulationLockDate;
    },
    userWithLockUsername() {
      if (this.userWithLock != null) {
        return this.userWithLock?.username;
      } else {
        return "";
      }
    },

    showLockOverlay() {
      return !this.userHasLock && this.tab !== this.cashflowTab;
    },
    isUnlock() {
      return this.isLock === false;
    },
    costItemFamiliesFiltered() {
      return this.costItemFamilies.filter((el) => el.name !== "Fletes");
    },
    deleteQueryParams() {
      let q = [];
      if (this.speciesIdFilter) {
        q.push(`species_id:${this.speciesIdFilter}`);
      }
      if (this.costItemFamilyIdFilter) {
        q.push(`cost_item_family_id:${this.costItemFamilyIdFilter}`);
      }
      if (this.regionIdFilter) {
        q.push(`region_id:${this.regionIdFilter}`);
      }
      return q.join(",");
    },
    modifiersTableData() {
      let rentModifiersTableData = [];
      let yieldModifiersTableData = [];
      let freightModifiersTableData = [];
      let costItemFamilyModifiersTableData = [];

      for (const region of this.regions) {
        // Rent Modifier (only region)
        let rentActiveMod = this.rentModifiers?.find(
          (el) => region?.id == el?.regionId
        );
        if (rentActiveMod) {
          rentModifiersTableData.push({
            id: rentActiveMod?.id,
            regionId: region?.id,
            regionName: region?.abbreviation,
            modifier: rentActiveMod?.modifier,
            parsedModifier: `${rentActiveMod?.modifier}qq`,
          });
        } else {
          rentModifiersTableData.push({
            id: null,
            regionId: region?.id,
            regionName: region?.abbreviation,
            modifier: null,
            parsedModifier: "-",
          });
        }
        for (const specie of this.species) {
          // Freight Modifier (only region and species)
          let freightActiveMod = this.freightModifiers?.find(
            (el) => region?.id == el?.regionId && specie?.id == el?.speciesId
          );
          if (freightActiveMod) {
            freightModifiersTableData.push({
              id: freightActiveMod?.id,
              regionId: region?.id,
              regionName: region?.abbreviation,
              speciesId: specie?.id,
              speciesName: specie?.name,
              modifier: freightActiveMod?.modifier,
              parsedModifier: `${freightActiveMod?.modifier * 100}%`,
            });
          } else {
            freightModifiersTableData.push({
              id: null,
              regionId: region?.id,
              regionName: region?.abbreviation,
              speciesId: specie?.id,
              speciesName: specie?.name,
              modifier: 1,
              parsedModifier: "100%",
            });
          }

          // Yield Modifier (only region and species)
          let yieldActiveMod = this.yieldModifiers?.find(
            (el) => region?.id == el?.regionId && specie?.id == el?.speciesId
          );
          if (yieldActiveMod) {
            yieldModifiersTableData.push({
              id: yieldActiveMod?.id,
              regionId: region?.id,
              regionName: region?.abbreviation,
              speciesId: specie?.id,
              speciesName: specie?.name,
              modifier: yieldActiveMod?.modifier,
              parsedModifier: `${yieldActiveMod?.modifier * 100}%`,
            });
          } else {
            yieldModifiersTableData.push({
              id: null,
              regionId: region?.id,
              regionName: region?.abbreviation,
              speciesId: specie?.id,
              speciesName: specie?.name,
              modifier: 1,
              parsedModifier: "100%",
            });
          }
          for (const costItemFamily of this.costItemFamiliesFiltered) {
            // CostItemFamily Modifier (species, region and costItemFamilyId)
            let costItemFamilyActiveMod = this.costItemFamilyModifiers?.find(
              (el) =>
                region?.id == el?.regionId &&
                specie?.id == el?.speciesId &&
                costItemFamily?.id == el?.costItemFamilyId
            );
            if (costItemFamilyActiveMod) {
              costItemFamilyModifiersTableData.push({
                id: costItemFamilyActiveMod?.id,
                regionId: region?.id,
                regionName: region?.abbreviation,
                speciesId: specie?.id,
                speciesName: specie?.name,
                costItemFamilyId: costItemFamily?.id,
                costItemFamilyName: costItemFamily?.name,
                modifier: costItemFamilyActiveMod?.modifier,
                parsedModifier: `${costItemFamilyActiveMod?.modifier * 100}%`,
              });
            } else {
              costItemFamilyModifiersTableData.push({
                id: null,
                regionId: region?.id,
                regionName: region?.abbreviation,
                speciesId: specie?.id,
                speciesName: specie?.name,
                costItemFamilyId: costItemFamily?.id,
                costItemFamilyName: costItemFamily?.name,
                modifier: 1,
                parsedModifier: "100%",
              });
            }
          }
        }
      }
      return {
        yield: yieldModifiersTableData,
        freight: freightModifiersTableData,
        rent: rentModifiersTableData,
        costItemFamily: costItemFamilyModifiersTableData,
      };
    },

    yieldModifiersTableData() {
      return this.modifiersTableData?.yield;
    },
    freightModifiersTableData() {
      return this.modifiersTableData?.freight;
    },
    rentModifiersTableData() {
      return this.modifiersTableData?.rent;
    },
    priceModifiersTableData() {
      let priceModifiersTableData = [];

      for (const specie of this.species) {
        // Price Modifier (only species)
        let priceActiveMod = this.priceModifiers?.find(
          (el) => specie?.id == el?.speciesId
        );
        if (priceActiveMod) {
          priceModifiersTableData.push({
            id: priceActiveMod?.id,
            speciesId: specie?.id,
            speciesName: specie?.name,
            modifier: priceActiveMod?.modifier,
            parsedModifier: `${priceActiveMod?.modifier * 100}%`,
          });
        } else {
          priceModifiersTableData.push({
            id: null,
            speciesId: specie?.id,
            speciesName: specie?.name,
            modifier: 1,
            parsedModifier: "100%",
          });
        }
      }
      return priceModifiersTableData;
    },
    costItemFamilyModifiersTableData() {
      return this.modifiersTableData?.costItemFamily;
    },

    activeSelectedModifiers() {
      return this.filteredModTableData?.filter((el) => el?.id !== null);
    },
    inActiveSelectedModifiers() {
      return this.filteredModTableData?.filter((el) => el?.id === null);
    },
    hasActiveModifiersInTab() {
      if (this.tab == this.yieldTab) {
        return this.yieldModifiers?.length > 0;
      } else if (this.tab == this.priceTab) {
        return this.priceModifiers?.length > 0;
      } else if (this.tab == this.costItemFamilyTab) {
        return this.costItemFamilyModifiers?.length > 0;
      } else if (this.tab == this.freightTab) {
        return this.freightModifiers?.length > 0;
      } else if (this.tab == this.rentTab) {
        return this.rentModifiers?.length > 0;
      } else {
        return false;
      }
    },
    hasActiveCampaignModifiers() {
      if (
        this.yieldModifiers?.length > 0 ||
        this.priceModifiers?.length > 0 ||
        this.costItemFamilyModifiers?.length > 0 ||
        this.freightModifiers?.length > 0 ||
        this.rentModifiers?.length > 0
      ) {
        return true;
      }
      return false;
    },

    modTableData() {
      if (this.modifiersTypeFilter === "all") {
        if (this.tab == this.yieldTab) {
          return this.yieldModifiersTableData;
        } else if (this.tab == this.priceTab) {
          return this.priceModifiersTableData;
        } else if (this.tab == this.costItemFamilyTab) {
          return this.costItemFamilyModifiersTableData;
        } else if (this.tab == this.freightTab) {
          return this.freightModifiersTableData;
        } else if (this.tab == this.rentTab) {
          return this.rentModifiersTableData;
        }
      }
      if (this.modifiersTypeFilter === "activeOnly") {
        // Actives have ids
        if (this.tab == this.yieldTab) {
          return this.yieldModifiersTableData.filter((el) => el?.id !== null);
        } else if (this.tab == this.priceTab) {
          return this.priceModifiersTableData.filter((el) => el?.id !== null);
        } else if (this.tab == this.costItemFamilyTab) {
          return this.costItemFamilyModifiersTableData.filter(
            (el) => el?.id !== null
          );
        } else if (this.tab == this.freightTab) {
          return this.freightModifiersTableData.filter((el) => el?.id !== null);
        } else if (this.tab == this.rentTab) {
          return this.rentModifiersTableData.filter((el) => el?.id !== null);
        }
      }
      if (this.modifiersTypeFilter === "inActiveOnly") {
        // Inactives don't have ids
        if (this.tab == this.yieldTab) {
          return this.yieldModifiersTableData.filter((el) => el?.id === null);
        } else if (this.tab == this.priceTab) {
          return this.priceModifiersTableData.filter((el) => el?.id === null);
        } else if (this.tab == this.costItemFamilyTab) {
          return this.costItemFamilyModifiersTableData.filter(
            (el) => el?.id === null
          );
        } else if (this.tab == this.freightTab) {
          return this.freightModifiersTableData.filter((el) => el?.id === null);
        } else if (this.tab == this.rentTab) {
          return this.rentModifiersTableData.filter((el) => el?.id === null);
        }
      }
      return [];
    },

    filteredModTableData() {
      let data = this.modTableData;
      if (this.regionIdFilter) {
        data = data.filter((el) => el?.regionId == this.regionIdFilter);
      }
      if (this.speciesIdFilter) {
        data = data.filter((el) => el?.speciesId == this.speciesIdFilter);
      }
      if (this.costItemFamilyIdFilter) {
        data = data.filter(
          (el) => el?.costItemFamilyId == this.costItemFamilyIdFilter
        );
      }
      return data;
    },
    modTableHeaders() {
      if (this.tab == this.yieldTab) {
        return this.yieldModifiersDataTableHeaders;
      } else if (this.tab == this.priceTab) {
        return this.priceModifiersDataTableHeaders;
      } else if (this.tab == this.costItemFamilyTab) {
        return this.costItemFamilyModifiersDataTableHeaders;
      } else if (this.tab == this.freightTab) {
        return this.freightModifiersDataTableHeaders;
      } else if (this.tab == this.rentTab) {
        return this.rentModifiersDataTableHeaders;
      }
      return "";
    },
    modTableTypeName() {
      if (this.tab == this.yieldTab) {
        return "Rinde";
      } else if (this.tab == this.priceTab) {
        return "Precio";
      } else if (this.tab == this.costItemFamilyTab) {
        return "Costos de Familia";
      } else if (this.tab == this.freightTab) {
        return "Flete";
      } else if (this.tab == this.rentTab) {
        return "Alquiler";
      }
      return "";
    },
    activeTabName() {
      if (this.tab == this.yieldTab) {
        return "yield";
      } else if (this.tab == this.priceTab) {
        return "price";
      } else if (this.tab == this.costItemFamilyTab) {
        return "costItemFamily";
      } else if (this.tab == this.freightTab) {
        return "freight";
      } else if (this.tab == this.rentTab) {
        return "rent";
      }
      return "cashflow";
    },
    modTableEndpoint() {
      if (this.tab == this.yieldTab) {
        return "/yield";
      } else if (this.tab == this.priceTab) {
        return "/price";
      } else if (this.tab == this.costItemFamilyTab) {
        return "/cost_item_family";
      } else if (this.tab == this.freightTab) {
        return "/freight";
      } else if (this.tab == this.rentTab) {
        return "/rent";
      }
      return "";
    },
    alertUserModifiersFiltersApplied() {
      let typeOfModifiers =
        this.modifiersTypeFilter == "inActiveOnly"
          ? "INACTIVOS"
          : this.modifiersTypeFilter == "activeOnly"
          ? "ACTIVOS"
          : "";
      if (!this.allowEdit) {
        return "";
      }
      if (
        !this.speciesIdFilter &&
        !this.regionIdFilter &&
        !this.costItemFamilyIdFilter
      ) {
        return `¡Se estan modificando TODOS los elementos ${typeOfModifiers}!`;
      } else if (
        this.speciesIdFilter &&
        !this.regionIdFilter &&
        !this.costItemFamilyIdFilter &&
        // tab !== 1 is to prevent alerts when price tab is selected
        this.tab !== this.priceTab
      ) {
        return (
          `¡Se estan modificando TODOS los elementos ${typeOfModifiers} de la de la especie ` +
          this.species.find((el) => el.id == this.speciesIdFilter).name +
          "!"
        );
      } else if (
        !this.speciesIdFilter &&
        this.regionIdFilter &&
        !this.costItemFamilyIdFilter
      ) {
        return (
          `¡Se estan modificando TODOS los elementos ${typeOfModifiers} de la region ` +
          this.regions.find((el) => el.id == this.regionIdFilter).abbreviation +
          "!"
        );
      } else if (
        !this.speciesIdFilter &&
        !this.regionIdFilter &&
        this.costItemFamilyIdFilter
      ) {
        return (
          `¡Se estan modificando TODOS los elementos ${typeOfModifiers} de la familia ` +
          this.costItemFamiliesFiltered.find(
            (el) => el.id == this.costItemFamilyIdFilter
          ).name +
          "!"
        );
      }
      return "";
    },
    campaignKpis() {
      if (!this.campaignMetrics?.kpi || !this.campaignSimulationMetrics?.kpi) {
        return [];
      } else {
        return makeCampaignSimulationInfoGrid(
          this.campaignMetrics?.kpi,
          this.campaignSimulationMetrics?.kpi
        );
      }
    },
    campaignKpi() {
      if (!this.campaignKpis.length > 0) {
        return [];
      } else {
        return [this.campaignKpis?.[0]];
      }
    },
    campaignSimulationKpi() {
      if (!this.campaignKpis.length > 1) {
        return [];
      } else {
        return [this.campaignKpis?.[1]];
      }
    },
    campaignTotalHaToolTip() {
      return campaignLevelTotalHaToolTip;
    },
    campaignStartDate() {
      return this.singleAbstractCampaign?.startDate;
    },
    campaignEndDate() {
      return this.singleAbstractCampaign?.endDate;
    },
    cashflowEndMonth() {
      let date = new Date(this.campaignEndDate);

      return date.getMonth() + 1;
    },
    cashflowStartMonth() {
      let date = new Date(this.campaignStartDate);

      return date.getMonth() + 1;
    },
    cashflowEndYear() {
      let date = new Date(this.campaignEndDate);

      return date.getFullYear();
    },
    cashflowStartYear() {
      let date = new Date(this.campaignStartDate);

      return date.getFullYear();
    },
    // wip
    isCampaignActivitiesEmpty() {
      return this.campaignActivities.length === 0;
    },
    engagementCashflowColumns() {
      return unPackAndProcessCashflowKeys(this.campaignCashflow?.keys);
    },
    engagementCashflowRows() {
      return this.campaignCashflow?.rows;
    },
    campaignId() {
      return parseInt(this.$route.params.id2);
    },
    userLockSimulationName() {
      return true;
    },
    isUserManager() {
      return this.userType == "manager";
    },
    userType() {
      return this.userTokenInfo?.utype;
    },
    userId() {
      return this.userTokenInfo?.sub;
    },
    userName() {
      return this.userTokenInfo?.uname;
    },
    isLock() {
      return this.singleCampaign.userIdSimulationLock !== null;
    },
    userHasLock() {
      return this.singleCampaign.userIdSimulationLock == this.userId;
    },
    processedSingleCampaign() {
      return {
        id: this.singleCampaign.id,
        version: this.singleCampaign.version,
        name: this.singleAbstractCampaign.name,
        processedName:
          this.singleAbstractCampaign.name + " v" + this.singleCampaign.version,
        endDate: this.singleAbstractCampaign.endDate?.slice(0, 10),
        startDate: this.singleAbstractCampaign.startDate?.slice(0, 10),
        simulationLockDate: parseToLocaleString(
          new Date(this.singleCampaign?.userLockDatetime)
        ),
      };
    },
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.checkForKeyShortCut);
  },
  async mounted() {
    window.addEventListener("keydown", this.checkForKeyShortCut);
    if (this.isFullLoad) {
      this.$store.dispatch("setPageLoadingStatus", true);

      this.$store.dispatch("getSingleCampaign", this.$route.params.id2);
      this.$store.dispatch("getCostItemFamily");
      this.$store.dispatch("getEstablishments");
      this.$store.dispatch("getSpecies");
      await Promise.all([
        this.getAllActiveModifiers(),
        this.$store.dispatch(
          "getSingleAbstractCampaign",
          this.$route.params.id1
        ),
        this.$store.dispatch("getCampaignActivities", {
          filterKey: "campaign_id",
          filterValue: this.$route.params.id2,
        }),
      ]);
      document.title = this.processedSingleCampaign?.processedName;
      if (!this.isCampaignActivitiesEmpty) {
        await Promise.all([
          this.getCampaignSimulationCashflow(),
          this.getCampaignSimulationMetrics(),
          this.getCampaignMetrics(),
        ]);
      }
      this.getUserLockName();

      this.$store.dispatch("setPageLoadingStatus", false);
    }
  },
  components: {
    AbstractDraftMetricDataTable,
    InfoGrid,
    DenseCashflowTable,
  },
};
</script>

<style>
.v-overlay--active {
  border-color: #00b144 !important;
  border-radius: 5px !important;
}
@keyframes blinking {
  50% {
    opacity: 0;
  }
}
.blink {
  animation: blinking ease-out 2s infinite;
}
.background-grey {
  background-color: #fafafa;
  opacity: 2.2;
}
.slider-holder {
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.spans-5ch {
  width: 5ch;
}

.sliders-array-holder {
  min-width: 500px;
}

.slider-tip {
  min-width: 4ch;
}
</style>
