var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ml-1 mb-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('v-btn',{staticClass:"white--text mt-2",attrs:{"x-small":"","color":"warning","elevation":"0"},on:{"click":function () { return this$1.$router.push({
                path: ("/abstract-campaign/" + (this$1.$route.params.id1) + "/campaign/" + (this$1.$route.params.id2)),
              }); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-arrow-left ")])],1),_c('h1',{staticClass:"ml-5 mt-2",staticStyle:{"font-weight":"200"}},[_vm._v(" Simulador "+_vm._s(_vm.processedSingleCampaign.processedName)+" ")]),(!_vm.hasActiveCampaignModifiers)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5 mt-4 grey--text",attrs:{"elevation":"0","small":"","disabled":_vm.isUserManager || !_vm.userHasLock}},'v-btn',attrs,false),on),[_vm._v("Reestablecer Todos")])]}}],null,false,709174141)},[_c('span',[_vm._v("No hay modificadores Activos")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5 mt-4",attrs:{"elevation":"0","small":"","disabled":_vm.isUserManager || !_vm.userHasLock,"color":"red red--text lighten-5"},on:{"click":_vm.resetAllModifiers}},'v-btn',attrs,false),on),[_vm._v("Reestablecer Todo")])]}}])},[_c('span',[_vm._v("Esta acción eliminara TODOS los modificadores")])])],1),_c('v-expansion-panels',{staticClass:"mb-2",attrs:{"flat":"","multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('b',{class:'ml-2 grey--text h2'},[_vm._v(" KPIs de Campaña ")])]),_c('v-expansion-panel-content',[_c('InfoGrid',{attrs:{"infos":_vm.campaignKpi,"valueClass":"display-2"}})],1)],1),_c('v-expansion-panel',{staticClass:"my-1"},[_c('v-divider')],1),_c('v-expansion-panel',{staticClass:"mb-5"},[_c('v-expansion-panel-header',[_c('b',{class:'ml-2 grey--text h2'},[_vm._v(" KPIs de Simulación ")])]),_c('v-expansion-panel-content',[_c('InfoGrid',{attrs:{"infos":_vm.campaignSimulationKpi,"valueClass":"display-2"}})],1)],1)],1),_c('div',{staticClass:"d-flex flex-row align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(!_vm.isLock)?_c('v-icon',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":""}},'v-icon',attrs,false),on),[_vm._v("mdi-lock-open-outline")]):_c('v-icon',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-lock-outline")])]}}])},[(!_vm.isLock)?_c('span',[_vm._v("Desbloqueado")]):_c('span',[_vm._v("Bloqueado")])]),_c('v-tabs',{staticClass:"mb-5",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticClass:"ms-4",on:{"click":function($event){_vm.resetFilters();
              _vm.exitEdit();}}},[_vm._v("Rinde")]),_c('v-tab',{on:{"click":function($event){_vm.resetFilters();
              _vm.exitEdit();}}},[_vm._v("Precio")]),_c('v-tab',{on:{"click":function($event){_vm.resetFilters();
              _vm.exitEdit();}}},[_vm._v("Costos de Familia")]),_c('v-tab',{on:{"click":function($event){_vm.resetFilters();
              _vm.exitEdit();}}},[_vm._v("Flete")]),_c('v-tab',{on:{"click":function($event){_vm.resetFilters();
              _vm.exitEdit();}}},[_vm._v("Alquiler Quintales Fijos")]),_c('v-tab',{on:{"click":function($event){_vm.resetFilters();
              _vm.exitEdit();}}},[_vm._v("Cashflow de Simulación")])],1),(_vm.userHasLock)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5 me-10",attrs:{"color":"primary","small":""},on:{"click":_vm.releaseUserLock}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":""}},[_vm._v(" mdi-lock-open-check-outline ")])],1)]}}],null,false,1071213366)},[_c('span',[_vm._v("Desbloquear para otros usuarios")])]):_vm._e(),(!_vm.isLock)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5 me-10",attrs:{"color":"warning","small":""},on:{"click":_vm.setUserLockIfNotSet}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":""}},[_vm._v(" mdi-pen-lock ")])],1)]}}],null,false,1363554250)},[_c('span',[_vm._v("Editar")])]):_vm._e()],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(_vm.isLock && !_vm.userHasLock)?_c('div',{staticClass:"\n            d-flex\n            flex-row\n            text-start\n            w-100\n            ms-2\n            mt-1\n            mb-2\n            justify-space-between\n          ",staticStyle:{"z-index":"100000"}},[_c('div',{staticClass:"me-2 d-flex"},[_c('div',[_c('v-icon',{staticClass:"me-1 blink",attrs:{"color":"primary"}},[_vm._v("mdi-account-badge-outline")]),_c('span',{staticClass:"caption font-weight-medium mt-1 me-4"},[_vm._v(_vm._s(_vm.userWithLockUsername)+" ")]),_c('v-icon',{staticClass:"me-1",attrs:{"color":"warning"}},[_vm._v("mdi-clock-edit-outline")]),_c('span',{staticClass:"caption font-weight-medium mt-1"},[_vm._v(_vm._s(this.simulationLockDate))])],1)])]):_vm._e(),(_vm.showLockOverlay)?_c('v-overlay',{staticClass:"pa-5 mb-2",attrs:{"opacity":0.08,"absolute":""}},[(_vm.isLock)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.isUserManager},on:{"click":_vm.setUserIdLock,"mouseover":function($event){_vm.showOpenLock = true},"mouseleave":function($event){_vm.showOpenLock = false}}},'v-btn',attrs,false),on),[(_vm.showOpenLock)?_c('v-icon',{attrs:{"color":""}},[_vm._v(" mdi-lock-open-variant-outline ")]):_c('v-icon',{attrs:{"color":""}},[_vm._v(" mdi-lock-outline ")])],1)]}}],null,false,1716661654)},[_c('span',[_vm._v("Desbloquear")])]):_vm._e()],1):_vm._e(),_c('v-tab-item',[_c('v-row',{},[_c('v-col',{staticClass:"ma-2"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-select',{staticClass:"managrx-input ma-2 pb-2",attrs:{"label":"Mostrar","items":[
                      { name: 'Todos', value: 'all' },
                      { name: 'Activos', value: 'activeOnly' },
                      { name: 'Inactivos', value: 'inActiveOnly' } ],"item-text":"name","item-value":"value","dense":"","type":"text"},on:{"change":_vm.exitEdit},model:{value:(_vm.modifiersTypeFilter),callback:function ($$v) {_vm.modifiersTypeFilter=$$v},expression:"modifiersTypeFilter"}}),_c('v-select',{staticClass:"managrx-input ma-2 pb-2",attrs:{"label":"Region","items":_vm.regions,"item-text":"abbreviation","item-value":"id","dense":"","clearable":"","type":"number"},on:{"change":_vm.exitEdit},model:{value:(_vm.regionIdFilter),callback:function ($$v) {_vm.regionIdFilter=$$v},expression:"regionIdFilter"}}),_c('v-select',{staticClass:"managrx-input ma-2 pb-2",attrs:{"label":"Especie","items":_vm.species,"item-text":"name","item-value":"id","clearable":"","dense":"","type":"number"},on:{"change":_vm.exitEdit},model:{value:(_vm.speciesIdFilter),callback:function ($$v) {_vm.speciesIdFilter=$$v},expression:"speciesIdFilter"}}),_c('v-btn',{staticClass:"ma-2 mt-2",attrs:{"elevation":"0","small":"","color":"info info--text lighten-5","disabled":!_vm.regionIdFilter &&
                      !_vm.speciesIdFilter &&
                      _vm.modifiersTypeFilter === 'all'},on:{"click":function($event){_vm.speciesIdFilter = null;
                      _vm.regionIdFilter = null;
                      _vm.modifiersTypeFilter = 'all';
                      _vm.exitEdit();}}},[_c('v-icon',[_vm._v("mdi-restart")])],1)],1),_c('div',{staticClass:"\n                    d-flex\n                    flex-column\n                    mt-3\n                    mb-4\n                    background-grey\n                    pa-2\n                    px-4\n                  "},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-slider',{staticClass:"mt-4",attrs:{"thumb-label":"","min":"20","max":"200","ticks":"always","step":"5","tick-size":"1","disabled":!_vm.allowEdit},model:{value:(_vm.modsNewValue),callback:function ($$v) {_vm.modsNewValue=$$v},expression:"modsNewValue"}}),_c('div',{staticClass:"d-flex flex-row align-top"},[_c('v-text-field',{ref:"inputTest",staticClass:"ms-2 me-2 mt-3",attrs:{"dense":"","type":"number","outlined":"","suffix":"%","readonly":!_vm.allowEdit,"rules":_vm.modRules},model:{value:(_vm.modsNewValue),callback:function ($$v) {_vm.modsNewValue=$$v},expression:"modsNewValue"}})],1),(_vm.allowEdit)?_c('v-btn',{staticClass:"mt-4 ms-3",attrs:{"elevation":"0","color":"primary","small":""},on:{"click":function($event){return _vm.saveEdits()}}},[_vm._v(" Guardar ")]):_vm._e(),(_vm.filteredModTableData.length == 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-4 ms-3 grey--text",attrs:{"elevation":"0","small":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.editText))])]}}],null,false,3952753247)},[_c('span',[_vm._v("Ningun filtro seleccionado")])]):_c('v-btn',{staticClass:"mt-4 ms-3",attrs:{"elevation":"0","color":_vm.editButtonColor,"small":"","disabled":_vm.isUserManager},on:{"click":function($event){!_vm.allowEdit ? _vm.enterEdit() : _vm.exitEdit()}}},[_vm._v(_vm._s(_vm.editText))])],1),_c('div',{staticClass:"d-flex flex-row mb-0 pb-0"},[_c('span',{staticClass:"text-caption warning--text"},[_vm._v(" "+_vm._s(_vm.alertUserModifiersFiltersApplied)+" ")])])])])])],1)],1),_c('v-tab-item',[_c('v-row',{},[_c('v-col',{staticClass:"ma-2"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-select',{staticClass:"managrx-input ma-2 pb-2",attrs:{"label":"Mostrar","items":[
                      { name: 'Todos', value: 'all' },
                      { name: 'Activos', value: 'activeOnly' },
                      { name: 'Inactivos', value: 'inActiveOnly' } ],"item-text":"name","item-value":"value","dense":"","type":"text"},on:{"change":_vm.exitEdit},model:{value:(_vm.modifiersTypeFilter),callback:function ($$v) {_vm.modifiersTypeFilter=$$v},expression:"modifiersTypeFilter"}}),_c('v-select',{staticClass:"managrx-input ma-2 pb-2",attrs:{"label":"Especie","items":_vm.species,"item-text":"name","item-value":"id","clearable":"","dense":"","type":"number"},on:{"change":_vm.exitEdit},model:{value:(_vm.speciesIdFilter),callback:function ($$v) {_vm.speciesIdFilter=$$v},expression:"speciesIdFilter"}}),_c('v-btn',{staticClass:"ma-2 mt-2",attrs:{"elevation":"0","small":"","color":"info info--text lighten-5","disabled":!_vm.speciesIdFilter && _vm.modifiersTypeFilter === 'all'},on:{"click":function($event){_vm.speciesIdFilter = null;
                      _vm.modifiersTypeFilter = 'all';
                      _vm.exitEdit();}}},[_c('v-icon',[_vm._v("mdi-restart")])],1)],1),_c('div',{staticClass:"\n                    d-flex\n                    flex-column\n                    mt-3\n                    mb-4\n                    background-grey\n                    pa-2\n                    px-4\n                  "},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-slider',{staticClass:"mt-4",attrs:{"thumb-label":"","min":"20","max":"200","ticks":"always","step":"5","tick-size":"1","disabled":!_vm.allowEdit},model:{value:(_vm.modsNewValue),callback:function ($$v) {_vm.modsNewValue=$$v},expression:"modsNewValue"}}),_c('div',{staticClass:"d-flex flex-row align-top"},[_c('v-text-field',{ref:"inputTest",staticClass:"ms-2 me-2 mt-3",attrs:{"dense":"","type":"number","outlined":"","suffix":"%","readonly":!_vm.allowEdit,"rules":_vm.modRules},model:{value:(_vm.modsNewValue),callback:function ($$v) {_vm.modsNewValue=$$v},expression:"modsNewValue"}})],1),(_vm.allowEdit)?_c('v-btn',{staticClass:"mt-4 ms-3",attrs:{"elevation":"0","color":"primary","small":""},on:{"click":function($event){return _vm.saveEdits()}}},[_vm._v(" Guardar ")]):_vm._e(),(_vm.filteredModTableData.length == 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-4 ms-3 grey--text",attrs:{"elevation":"0","small":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.editText))])]}}],null,false,3952753247)},[_c('span',[_vm._v("Ningun filtro seleccionado")])]):_c('v-btn',{staticClass:"mt-4 ms-3",attrs:{"color":_vm.editButtonColor,"elevation":"0","small":"","disabled":_vm.isUserManager},on:{"click":function($event){!_vm.allowEdit ? _vm.enterEdit() : _vm.exitEdit()}}},[_vm._v(_vm._s(_vm.editText))])],1),_c('div',{staticClass:"d-flex flex-row"},[_c('span',{staticClass:"text-caption warning--text"},[_vm._v(" "+_vm._s(_vm.alertUserModifiersFiltersApplied)+" ")])])])])])],1)],1),_c('v-tab-item',[_c('v-row',{},[_c('v-col',{staticClass:"ma-2"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-select',{staticClass:"managrx-input ma-2 pb-2",attrs:{"label":"Mostrar","items":[
                      { name: 'Todos', value: 'all' },
                      { name: 'Activos', value: 'activeOnly' },
                      { name: 'Inactivos', value: 'inActiveOnly' } ],"item-text":"name","item-value":"value","dense":"","type":"text"},on:{"change":_vm.exitEdit},model:{value:(_vm.modifiersTypeFilter),callback:function ($$v) {_vm.modifiersTypeFilter=$$v},expression:"modifiersTypeFilter"}}),_c('v-select',{staticClass:"managrx-input ma-2 pb-2",attrs:{"label":"Region","items":_vm.regions,"item-text":"abbreviation","item-value":"id","dense":"","clearable":"","type":"number"},on:{"change":_vm.exitEdit},model:{value:(_vm.regionIdFilter),callback:function ($$v) {_vm.regionIdFilter=$$v},expression:"regionIdFilter"}}),_c('v-select',{staticClass:"managrx-input ma-2 pb-2",attrs:{"label":"Especie","items":_vm.species,"item-text":"name","item-value":"id","clearable":"","dense":"","type":"number"},on:{"change":_vm.exitEdit},model:{value:(_vm.speciesIdFilter),callback:function ($$v) {_vm.speciesIdFilter=$$v},expression:"speciesIdFilter"}}),_c('v-select',{staticClass:"managrx-input ma-2 pb-2",attrs:{"label":"Familia","items":_vm.costItemFamiliesFiltered,"item-text":"name","item-value":"id","clearable":"","dense":"","type":"number"},on:{"change":_vm.exitEdit},model:{value:(_vm.costItemFamilyIdFilter),callback:function ($$v) {_vm.costItemFamilyIdFilter=$$v},expression:"costItemFamilyIdFilter"}}),_c('v-btn',{staticClass:"ma-2 mt-2",attrs:{"elevation":"0","small":"","color":"info info--text lighten-5","disabled":!_vm.regionIdFilter &&
                      !_vm.speciesIdFilter &&
                      !_vm.costItemFamilyIdFilter &&
                      _vm.modifiersTypeFilter === 'all'},on:{"click":function($event){_vm.regionIdFilter = null;
                      _vm.speciesIdFilter = null;
                      _vm.costItemFamilyIdFilter = null;
                      _vm.modifiersTypeFilter = 'all';
                      _vm.exitEdit();}}},[_c('v-icon',[_vm._v("mdi-restart")])],1)],1),_c('div',{staticClass:"\n                    d-flex\n                    flex-column\n                    mt-3\n                    mb-4\n                    background-grey\n                    pa-2\n                    px-4\n                  "},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-slider',{staticClass:"mt-4",attrs:{"thumb-label":"","min":"20","max":"200","ticks":"always","step":"5","tick-size":"1","disabled":!_vm.allowEdit},model:{value:(_vm.modsNewValue),callback:function ($$v) {_vm.modsNewValue=$$v},expression:"modsNewValue"}}),_c('div',{staticClass:"d-flex flex-row align-top"},[_c('v-text-field',{ref:"inputTest",staticClass:"ms-2 me-2 mt-3",attrs:{"dense":"","type":"number","outlined":"","suffix":"%","readonly":!_vm.allowEdit,"rules":_vm.modRules},model:{value:(_vm.modsNewValue),callback:function ($$v) {_vm.modsNewValue=$$v},expression:"modsNewValue"}})],1),(_vm.allowEdit)?_c('v-btn',{staticClass:"mt-4 ms-3",attrs:{"elevation":"0","color":"primary","small":"","disabled":_vm.isUserManager},on:{"click":function($event){return _vm.saveEdits()}}},[_vm._v(" Guardar ")]):_vm._e(),(_vm.filteredModTableData.length == 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-4 ms-3 grey--text",attrs:{"elevation":"0","small":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.editText))])]}}],null,false,3952753247)},[_c('span',[_vm._v("Ningun filtro seleccionado")])]):_c('v-btn',{staticClass:"mt-4 ms-3",attrs:{"elevation":"0","color":_vm.editButtonColor,"small":"","disabled":_vm.isUserManager},on:{"click":function($event){!_vm.allowEdit ? _vm.enterEdit() : _vm.exitEdit()}}},[_vm._v(_vm._s(_vm.editText))])],1),_c('div',{staticClass:"d-flex flex-row"},[_c('span',{staticClass:"text-caption warning--text"},[_vm._v(" "+_vm._s(_vm.alertUserModifiersFiltersApplied)+" ")])])])])])],1)],1),_c('v-tab-item',[_c('v-row',{},[_c('v-col',{staticClass:"ma-2"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-select',{staticClass:"managrx-input ma-2 pb-2",attrs:{"label":"Mostrar","items":[
                      { name: 'Todos', value: 'all' },
                      { name: 'Activos', value: 'activeOnly' },
                      { name: 'Inactivos', value: 'inActiveOnly' } ],"item-text":"name","item-value":"value","dense":"","type":"text"},on:{"change":_vm.exitEdit},model:{value:(_vm.modifiersTypeFilter),callback:function ($$v) {_vm.modifiersTypeFilter=$$v},expression:"modifiersTypeFilter"}}),_c('v-select',{staticClass:"managrx-input ma-2 pb-2",attrs:{"label":"Region","items":_vm.regions,"item-text":"abbreviation","item-value":"id","dense":"","clearable":"","type":"number"},on:{"change":_vm.exitEdit},model:{value:(_vm.regionIdFilter),callback:function ($$v) {_vm.regionIdFilter=$$v},expression:"regionIdFilter"}}),_c('v-select',{staticClass:"managrx-input ma-2 pb-2",attrs:{"label":"Especie","items":_vm.species,"item-text":"name","item-value":"id","clearable":"","dense":"","type":"number"},on:{"change":_vm.exitEdit},model:{value:(_vm.speciesIdFilter),callback:function ($$v) {_vm.speciesIdFilter=$$v},expression:"speciesIdFilter"}}),_c('v-btn',{staticClass:"ma-2 mt-2",attrs:{"elevation":"0","small":"","color":"info info--text lighten-5","disabled":!_vm.regionIdFilter &&
                      !_vm.speciesIdFilter &&
                      _vm.modifiersTypeFilter === 'all'},on:{"click":function($event){_vm.speciesIdFilter = null;
                      _vm.regionIdFilter = null;
                      _vm.modifiersTypeFilter = 'all';
                      _vm.exitEdit();}}},[_c('v-icon',[_vm._v("mdi-restart")])],1)],1),_c('div',{staticClass:"\n                    d-flex\n                    flex-column\n                    mt-3\n                    mb-4\n                    background-grey\n                    pa-2\n                    px-4\n                  "},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-slider',{staticClass:"mt-4",attrs:{"thumb-label":"","min":"20","max":"200","ticks":"always","step":"5","tick-size":"1","disabled":!_vm.allowEdit},model:{value:(_vm.modsNewValue),callback:function ($$v) {_vm.modsNewValue=$$v},expression:"modsNewValue"}}),_c('div',{staticClass:"d-flex flex-row align-top"},[_c('v-text-field',{ref:"inputTest",staticClass:"ms-2 me-2 mt-3",attrs:{"dense":"","type":"number","outlined":"","suffix":"%","readonly":!_vm.allowEdit,"rules":_vm.modRules},model:{value:(_vm.modsNewValue),callback:function ($$v) {_vm.modsNewValue=$$v},expression:"modsNewValue"}})],1),(_vm.allowEdit)?_c('v-btn',{staticClass:"mt-4 ms-3",attrs:{"elevation":"0","color":"primary","small":""},on:{"click":function($event){return _vm.saveEdits()}}},[_vm._v(" Guardar ")]):_vm._e(),(_vm.filteredModTableData.length == 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-4 ms-3 grey--text",attrs:{"elevation":"0","small":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.editText))])]}}],null,false,3952753247)},[_c('span',[_vm._v("Ningun filtro seleccionado")])]):_c('v-btn',{staticClass:"mt-4 ms-3",attrs:{"elevation":"0","color":_vm.editButtonColor,"small":"","disabled":_vm.isUserManager},on:{"click":function($event){!_vm.allowEdit ? _vm.enterEdit() : _vm.exitEdit()}}},[_vm._v(_vm._s(_vm.editText))])],1),_c('div',{staticClass:"d-flex flex-row mb-0 pb-0"},[_c('span',{staticClass:"text-caption warning--text"},[_vm._v(" "+_vm._s(_vm.alertUserModifiersFiltersApplied)+" ")])])])])])],1)],1),_c('v-tab-item',[_c('v-row',{},[_c('v-col',{staticClass:"ma-2"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-select',{staticClass:"managrx-input ma-2 pb-2",attrs:{"label":"Mostrar","items":[
                      { name: 'Todos', value: 'all' },
                      { name: 'Activos', value: 'activeOnly' },
                      { name: 'Inactivos', value: 'inActiveOnly' } ],"item-text":"name","item-value":"value","dense":"","type":"text"},on:{"change":_vm.exitEdit},model:{value:(_vm.modifiersTypeFilter),callback:function ($$v) {_vm.modifiersTypeFilter=$$v},expression:"modifiersTypeFilter"}}),_c('v-select',{staticClass:"managrx-input ma-2 pb-2",attrs:{"label":"Region","items":_vm.regions,"item-text":"abbreviation","item-value":"id","dense":"","clearable":"","type":"number"},on:{"change":_vm.exitEdit},model:{value:(_vm.regionIdFilter),callback:function ($$v) {_vm.regionIdFilter=$$v},expression:"regionIdFilter"}}),_c('v-btn',{staticClass:"ma-2 mt-2",attrs:{"elevation":"0","small":"","color":"info info--text lighten-5","disabled":!_vm.regionIdFilter && _vm.modifiersTypeFilter === 'all'},on:{"click":function($event){_vm.regionIdFilter = null;
                      _vm.modifiersTypeFilter = 'all';
                      _vm.exitEdit();}}},[_c('v-icon',[_vm._v("mdi-restart")])],1)],1),_c('div',{staticClass:"\n                    d-flex\n                    flex-column\n                    mt-3\n                    mb-4\n                    background-grey\n                    pa-2\n                    px-4\n                  "},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-slider',{staticClass:"mt-4",attrs:{"thumb-label":"","min":"-20","max":"20","ticks":"always","step":"1","tick-size":"0","disabled":!_vm.allowEdit},model:{value:(_vm.rentModsNewValue),callback:function ($$v) {_vm.rentModsNewValue=$$v},expression:"rentModsNewValue"}}),_c('div',{staticClass:"d-flex flex-row align-top"},[_c('v-text-field',{ref:"inputTest",staticClass:"ms-2 me-2 mt-3",attrs:{"dense":"","type":"number","outlined":"","suffix":"qq","readonly":!_vm.allowEdit,"rules":_vm.rentModRules},model:{value:(_vm.rentModsNewValue),callback:function ($$v) {_vm.rentModsNewValue=$$v},expression:"rentModsNewValue"}})],1),(_vm.allowEdit)?_c('v-btn',{staticClass:"mt-4 ms-3",attrs:{"elevation":"0","color":"primary","small":""},on:{"click":function($event){return _vm.saveEdits()}}},[_vm._v(" Guardar ")]):_vm._e(),(_vm.filteredModTableData.length == 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-4 ms-3 grey--text",attrs:{"elevation":"0","small":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.editText))])]}}],null,false,3952753247)},[_c('span',[_vm._v("Ningun filtro seleccionado")])]):_c('v-btn',{staticClass:"mt-4 ms-3",attrs:{"color":_vm.editButtonColor,"elevation":"0","small":"","disabled":_vm.isUserManager},on:{"click":function($event){!_vm.allowEdit ? _vm.enterEdit() : _vm.exitEdit()}}},[_vm._v(_vm._s(_vm.editText))])],1),_c('div',{staticClass:"d-flex flex-row mb-0 pb-0"},[_c('span',{staticClass:"text-caption warning--text"},[_vm._v(" "+_vm._s(_vm.alertUserModifiersFiltersApplied)+" ")])])])])])],1)],1),_c('v-tab-item',[_c('DenseCashflowTable',{attrs:{"rows":_vm.engagementCashflowRows,"totalHaToolTip":_vm.campaignTotalHaToolTip,"columns":_vm.engagementCashflowColumns,"isResourceUtilization":false,"startDate":_vm.campaignStartDate,"endDate":_vm.campaignEndDate}})],1)],1)],1),(_vm.tab != _vm.cashflowTab)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row justify-space-between mt-0 pt-0 mb-2"},[(_vm.activeSelectedModifiers.length === 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey--text mr-2 mt-2",attrs:{"elevation":"0","small":"","disabled":_vm.isUserManager || !_vm.userHasLock}},'v-btn',attrs,false),on),[_vm._v("Reestablecer Seleccionados")])]}}],null,false,1835680463)},[_c('span',[_vm._v("Ningun modificador activo seleccionado")])]):_c('v-btn',{staticClass:"mr-2 mt-2",attrs:{"elevation":"0","small":"","disabled":_vm.isUserManager || !_vm.userHasLock,"color":"red red--text lighten-5"},on:{"click":_vm.resetSelectedModifiers}},[_vm._v("Reestablecer Seleccionados")]),_c('span',{staticClass:"text-overline grey--text text-subtitle-2 mt-1",staticStyle:{"font-size":"15px !important"}},[_vm._v(" Resultados: "+_vm._s(this.filteredModTableData.length)+" ")])],1),_c('AbstractDraftMetricDataTable',{attrs:{"title":'Modificadores por ' + _vm.modTableTypeName,"hideFooter":false,"orderBy":"parsedModifier","desc":true,"items":_vm.filteredModTableData,"headers":_vm.modTableHeaders,"headersWidth":100,"hasSearch":false,"grupByStatus":false}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }